
export {token};
// token
var val =localStorage.getItem('userInfo')&&JSON.parse(localStorage.getItem('userInfo')).token
var token ={
    token:val===null ? null:val,
};


export {urlAll}
var urlAll =process.env.VUE_APP_URL



// timeDay
export function timeDay(val) {
    var date = new Date(val)
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var time = y + '-' + m + '-' + d
    return time
}

// rgb to 16进制
export function  colorRGBtoHex(color) {
    var rgb = color.split(',');
    var r = parseInt(rgb[0]);
    var g = parseInt(rgb[1]);
    var b = parseInt(rgb[2]);
    var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    return hex;
}

export function  colorRGB(color) {
    var rgb = color.split(',');
    var r = parseInt(rgb[0]);
    var g = parseInt(rgb[1]);
    var b = parseInt(rgb[2]);
    var hexval = ''
    if(r<50 || g<0|| b<50){
        hexval = '#333'
    } else {
        hexval = '#fff'
    }
    
    return hexval;
}



// 16进制 to rgb
export function  hex2rgb(a){
    if (a == ""){
        return ""
    }
    a=a.substring(1);
    a=a.toLowerCase();
    b=new Array();
    for(x=0;x<3;x++){
        b[0]=a.substr(x*2,2);
        b[3]="0123456789abcdef";
        b[1]=b[0].substr(0,1);
        b[2]=b[0].substr(1,1);
        b[20+x]=b[3].indexOf(b[1])*16+b[3].indexOf(b[2]);
    }
    return "rgb(" +  b[20]+","+b[21]+","+b[22] + ")";
}




//根据深浅色背景，返回黑白文字颜色。
export function dynamicColor(bg){
    // console.log('传入颜色：',bg)
    if(!bg){    //默认中色
        return '#999';
    }
    if(bg.search('gradient')!=-1){     //渐变色直接返回中色值
        return '#999';
    }
    if(bg.search('#')!=-1){     //16进制转换成rgb
        bg=set16ToRgb(bg);
    }
    if(bg.search('rgb')!=-1){     //rgb
 
    }
    var bgcolor = bg.replace("rgb(", "").replace("rgba(", "").replace(")", "");
    var bgcolorArry = bgcolor.split(",");
    // var color = bgcolorArry[0] * 0.299 + bgcolorArry[1] * 0.587 + bgcolorArry[2] * 0.114;
    // if (color >= 192) {     //字体深色
    //     return '#999';
    // } else {        //字体浅色
    //     return 'rgba(255,255,255,0.5)';
    // }
    return isLight(bgcolorArry) ? '#444' : 'rgba(255,255,255,0.5)';//浅色背景就返回深色文字颜色。
}
//是否浅色
function isLight (rgb=[0,0,0]) {
    return (0.213 * rgb[0] +0.715 * rgb[1] +0.072 * rgb[2] >255 / 2);
};



//#444 转成 -- rgba （4，4，4，.5）
export function bjolor(bg){
    // console.log('传入颜色：',bg)
    var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
   if(!reg.test(bg)){return;}
   let newStr = (bg.toLowerCase()).replace(/\#/g,'')
   let len = newStr.length;
   if(len == 3){
       let t = ''
       for(var i=0;i<len;i++){
           t += newStr.slice(i,i+1).concat(newStr.slice(i,i+1))
       }
       newStr = t
   }
   let arr = []; //将字符串分隔，两个两个的分隔
   for(var i =0;i<6;i=i+2){
       let s = newStr.slice(i,i+2)
       arr.push(parseInt("0x" + s))
   }
   arr.push(.6)
   return 'rgb(' + arr.join(",") + '  )';
}
export function oneolor(bg){
    // console.log('传入颜色：',bg)
    var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
   if(!reg.test(bg)){return;}
   let newStr = (bg.toLowerCase()).replace(/\#/g,'')
   let len = newStr.length;
   if(len == 3){
       let t = ''
       for(var i=0;i<len;i++){
           t += newStr.slice(i,i+1).concat(newStr.slice(i,i+1))
       }
       newStr = t
   }
   let arr = []; //将字符串分隔，两个两个的分隔
   for(var i =0;i<6;i=i+2){
       let s = newStr.slice(i,i+2)
       arr.push(parseInt("0x" + s))
   }
   arr.push(.1)
   return 'rgb(' + arr.join(",") + '  )';
}












 
//【16进制转换为RGB 】
// set16ToRgb('#ffffff');   // rgb(255,255,0)
function set16ToRgb(str){
   var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
   if(!reg.test(str)){return;}
   let newStr = (str.toLowerCase()).replace(/\#/g,'')
   let len = newStr.length;
   if(len == 3){
       let t = ''
       for(var i=0;i<len;i++){
           t += newStr.slice(i,i+1).concat(newStr.slice(i,i+1))
       }
       newStr = t
   }
   let arr = []; //将字符串分隔，两个两个的分隔
   for(var i =0;i<6;i=i+2){
       let s = newStr.slice(i,i+2)
       arr.push(parseInt("0x" + s))
   }
   return 'rgb(' + arr.join(",")  + ')';
}
 
//【RGB转换为16进制 】
// setRgbTo16('rgb(255,0,255)');   // #FF00FF 
function setRgbTo16(str){
    let reg = /^(rgb|RGB)/;
    if(!reg.test(str)){return;}
    var arr = str.slice(4, str.length-1).split(",")
    let color = '#';
    for(var i=0;i<arr.length;i++){
         var t = Number(arr[i]).toString(16)
         if(t == "0"){   //如果为“0”的话，需要补0操作,否则只有5位数
             t =  t + "0"
         }
         color += t;
    }
    return color;
}

